const state = {
    cart: [],
};

const mutations = {
    ADD_PRODUCT(state, value) {
        state.cart.push(value);
    },
    REMOVE(state, value) {
        let nState = []
        state.cart.forEach(elt => {
            if (elt.id != value) nState.push(elt)
        })
        state.cart = nState
    },
    CLEAR_CART(state) {
        state.cart = [];
    },
};

const getters = {
    getCart: (state) => {
        return state.cart;
    },
};

const actions = {
    addProduct({ commit }, arg) {
        let product = arg;
        commit("ADD_PRODUCT", product);
    },
    remove({ commit }, arg) {
        let product = arg;
        commit("REMOVE", product);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};