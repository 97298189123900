<template>
    <header>
        <nav
            class="navbar"
            v-bind:class="{
                'rasing-in':
                    !first && (scrollPosition != 0 || scrollPosition != null),
                'rasing-out':
                    !first && (scrollPosition == 0 || scrollPosition == null),
            }"
        >
            <a class="title clickable" @click="goTo('Home')">WILD FOX</a>
            <ul>
                <router-link to="/?scroll=store">STORE</router-link>
                <router-link to="/?scroll=videos">VIDEOS</router-link>
                <router-link to="/contact">CONTACT</router-link>
                <li
                    class="clickable"
                    v-if="getCart.length > 0"
                    @click="goTo('Panier')"
                >
                    <img src="/icons/cart.svg" />
                </li>
            </ul>
        </nav>
    </header>
</template>

<style scoped>
.title {
    color: wheat;
}

@media (max-width: 920px) {
    .title {
        font-size: 1rem;
    }
}

.navbar {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
    align-items: center;
    justify-items: center;
}

.navbar ul {
    grid-column: 2;
    font-size: 0.7rem;
    position: relative;
    list-style-type: none;
    display: inline-flex;
    color: white;
}

.navbar ul * {
    margin: 0 5px;
}

@media (min-width: 550px) {
    .title {
        font-size: 2rem;
        white-space: nowrap;
    }
    .navbar ul {
        font-size: 1rem;
    }
    .navbar ul * {
        margin: 0 15px;
    }
}

@media (min-width: 920px) {
    .title {
        font-size: 3.5rem;
        white-space: nowrap;
    }
    .navbar ul {
        font-size: 1.6rem;
    }
}

@media (min-width: 780px) {
    .navbar {
        grid-template-columns: repeat(3, 1fr);
    }

    .navbar ul {
        grid-column: 3;
        display: inline-flex;
        padding: 0;
    }
}

.rasing-in {
    -webkit-animation: rasein 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: rasein 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
}

.rasing-out {
    -webkit-animation: raseout 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: raseout 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            scrollPosition: null,
            first: true,
        };
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY;
            this.first = false;
        },

        goTo(name) {
            this.$router.push({
                name: name,
            });
        },
    },
    mounted() {
        this.getRouter;
        window.addEventListener("scroll", this.updateScroll);
    },
    destroy() {
        window.removeEventListener("scroll", this.updateScroll);
    },
    computed: {
        ...mapGetters({
            getCart: "cartModule/getCart",
        }),
    },
};
</script>
