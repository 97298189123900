import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

function loadView(view) {
    return () =>
        import(`@/views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: loadView("Home"),
    },
    {
        path: "/panier",
        name: "Panier",
        component: loadView("Panier"),
    },
    {
        path: "/contact",
        name: "Contact",
        component: loadView("Contact"),
    },
    {
        path: "/product/:id",
        props: true,
        name: "Product",
        component: loadView("Product"),
    },
];

const router = new VueRouter({
    routes
});

export default router;