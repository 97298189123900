import Vue from "vue";
import Vuex from "vuex";

import cartModule from "./modules/cartModule";

Vue.use(Vuex);

const state = {};
const getters = {};
const mutations = {};
const actions = {};

const modules = {
    cartModule
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules
});