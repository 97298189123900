<template>
  <div id="app">
    <NavBar />
    <router-view />
    <notifications group="toast" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "App",
  components: {
    NavBar,
  },
  beforeMount() {
    
  },
  metaInfo() {
    return {
      title: "Wild Fox Band - Home",
      meta: [
        {
          name: "description",
          content: "Le site de merchandising du groupe Wild Fox !",
        },
        { property: "og:title", content: "Wild Fox Band - Home" },
        { property: "og:site_name", content: "Wild Fox Band" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style></style>
