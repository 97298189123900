import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import { loadStripe } from '@stripe/stripe-js';
import VueMeta from 'vue-meta'
import Notifications from 'vue-notification'
var VueScrollTo = require('vue-scrollto');
import VueGtag from "vue-gtag";
import "./assets/css/style.css"

let stripe = loadStripe('pk_live_51IBOroKWgMCTbsAVgpGnF9jUmO87v72weaynbvdlMXnWhKQqWAMNVOlUUFU676FPYkYtWnyKOi6uTNsrzb2OXU2600QBw7OFjJ');
// let stripe = loadStripe('pk_test_51IBOroKWgMCTbsAV9BgQWfG2LzRoM3hlabdBr2kQ6Zbe7D5Pg0y4ER4bvZCUjhGIoILmHF4r1oHhGEesPB4dqKJF00QlnDsxlR');


Vue.prototype.$stripe = stripe;
Vue.prototype.$API = "https://api.wildfoxband.com"
// Vue.prototype.$API = "https://wildfox-api.herokuapp.com"
// Vue.prototype.$API = "https://quali-wf-api.herokuapp.com"
// Vue.prototype.$API = "http://localhost:5050"

Vue.use(VueGtag, {
    config: { id: "G-QKHKZ7VRDS" }
});

Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: -100,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
